import React from "react"
import { Form } from "react-bootstrap"

const Message = ({ content, success }) => (
  <div
    className="success-form-message p-3 mt-3"
    style={success ? { visibility: "visible" } : { visibility: "hidden" }}
  >
    <p className="m-0">{content}</p>
  </div>
)

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const NewsletterForm = () => {
  const [email, setEmail] = React.useState("")
  const [touched, setTouched] = React.useState({})
  const [success, setSuccess] = React.useState(false)

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "newsletter-form",
        email,
      }),
    }).then(() => setSuccess(true))
    setEmail("")
    setTouched({})
    setTimeout(() => {
      setSuccess(false)
    }, 3000)
    e.preventDefault()
  }

  const onChange = e => setEmail(e.target.value)

  const onBlur = field => setTouched({ ...touched, [field]: true })

  return (
    <Form
      className="form-wrapper"
      name="newsletter-form"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
      method="post"
    >
      <div className="input-wrapper">
        <Form.Control
          placeholder="Write your email..."
          required
          id="email"
          name="email"
          type="email"
          onChange={onChange}
          onBlur={() => onBlur("email")}
          isInvalid={touched.email && !email}
          isValid={touched.email && email}
          value={email || ""}
        />
      </div>

      <div className="button-wrapper">
        <button
          type="submit"
          className="newsletter-add d-block btn btn-primary"
        >
          Keep me updated
          <span className="icon">
            <i className="fas fa-paper-plane"></i>
          </span>
        </button>
      </div>
      <Message
        success={success}
        content="Thank you for contacting us. We will get back to you soon!"
      />
    </Form>
  )
}

export default NewsletterForm
