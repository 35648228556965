import React from "react"
import { Link } from "gatsby"
import { Location } from "@reach/router"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Services = () => {
  return (
    <div id="services">
      <div className="container">
        <h2 className="heading">
          What we do at Labs42 <span className="labs-blue heading-dot"> .</span>
        </h2>
        <p className="subheading">
          We empower companies to build scalable high-quality technology
          products. Customers benefit from a fully coordinated development
          process and a team of talented engineers, designers and product
          managers.
          <br /> <br />
        </p>
        <div className="services row">
          <div className="service col-sm-6 col-lg-3">
            <LazyLoadImage
              src="/images/services/icons/consulting.svg"
              alt=""
              className="wwd-icon"
            />
            <h2>
              Consulting <span className="labs-blue"> .</span>
            </h2>
            <p className="gray">
              Let us help take your Application to the next level - planning the
              next big steps, reviewing architecture, and brainstorming with the
              team to ensure you achieve your most ambitious goals!
            </p>
          </div>
          <div className="service col-sm-6 col-lg-3">
            <LazyLoadImage
              src="/images/services/icons/development.svg"
              alt=""
              className="wwd-icon"
            />
            <h2>
              Development <span className="labs-blue"> .</span>
            </h2>
            <p className="gray">
              Labs42 can become part of your development process, making sure
              that you're building enterprise-grade, scalable applications with
              best-practices in mind, all while getting things done better and
              faster!
            </p>
          </div>
          <div className="service col-sm-6 col-lg-3">
            <LazyLoadImage
              src="/images/services/icons/workshops.svg"
              alt=""
              className="wwd-icon"
            />
            <h2>
              Workshops <span className="labs-blue"> .</span>
            </h2>
            <p className="gray">
              Have a Labs42 team member come to YOU! Get your team up to speed
              with guided workshops on a huge variety of topics. Modern Node.js
              development, JavaScript frameworks, Reactive Programming, or
              anything in between! We've got your back.
            </p>
          </div>
          <div className="service col-sm-6 col-lg-3">
            <LazyLoadImage
              src="/images/services/icons/open-source.svg"
              alt=""
              className="wwd-icon"
            />
            <h2>
              Open-source <span className="labs-blue"> .</span>
            </h2>
            <p className="gray">
              We love open-source because we love giving back to the community!
              We help maintain & contribute to open-source projects and hope to
              always share our knowledge with the world!
            </p>
          </div>
        </div>
        <Location>
          {({ location }) => (
            <Link
              to="/services/"
              className="btn btn-primary mt-4"
              activeClassName={location.pathname === "/" ? "active" : ""}
            >
              Explore more
              <svg
                enableBackground="new 0 0 32 32"
                height="32px"
                version="1.1"
                viewBox="0 0 32 32"
                width="32px"
                space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                className="arrow-icon"
              >
                <path
                  clipRule="evenodd"
                  d="M32,16.009c0-0.267-0.11-0.522-0.293-0.714  l-9.899-9.999c-0.391-0.395-1.024-0.394-1.414,0c-0.391,0.394-0.391,1.034,0,1.428l8.193,8.275H1c-0.552,0-1,0.452-1,1.01  s0.448,1.01,1,1.01h27.586l-8.192,8.275c-0.391,0.394-0.39,1.034,0,1.428c0.391,0.394,1.024,0.394,1.414,0l9.899-9.999  C31.894,16.534,31.997,16.274,32,16.009z"
                  fill="#fff"
                  fillRule="evenodd"
                  id="Arrow_Forward"
                ></path>
              </svg>
            </Link>
          )}
        </Location>
      </div>
    </div>
  )
}

export default Services
