import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Navbar from "@components/navbar"
import Footer from "@components/footer"
import Services from "@components/services"
import NewsletterForm from "@components/newsletter"
import Announcement from "@components/announcement"
import { LazyLoadImage } from "react-lazy-load-image-component"

// import { Col } from "react-bootstrap"
// import ReactPaginate from "react-paginate"
// import { MdNavigateNext, MdNavigateBefore } from "react-icons/md"
// import { ITEMS_PER_PAGE } from "../constants/blog"

const Blog = props => {
  const [items] = React.useState(props.data.allBlogItemsJson.nodes)
  // const [currentPage, setCurrentPage] = React.useState(1)

  // const blogRef = React.useRef(null)

  // const handleClickPagination = event => {
  //   setCurrentPage(Number(event.selected) + 1)
  //   blogRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  // }

  // const indexOfLastItem = currentPage * ITEMS_PER_PAGE
  // const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE
  // const currentItems = items.slice(indexOfFirstItem, indexOfLastItem)
  const currentItems = items

  const renderItems = currentItems.map(item => {
    return (
      <li key={item.id}>
        <div className="text-left">
          <div className="row">
            <LazyLoadImage
              src={item.image}
              alt=""
              className="author-photo ml-3 rounded-circle"
            />
            <h6 className="pt-2 pl-3">
              <strong>{item.author}</strong> <br />
              <span>
                on {item.dateTime}
                <span></span>
              </span>
            </h6>
          </div>
        </div>
        <br className="clearfix" />
        <h3 className="mt-2">
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            {item.title}
          </a>
        </h3>
        <p className="blog-description">{item.summary}</p>
        <a
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-secondary"
        >
          Read the Full Article
          <svg
            enableBackground="new 0 0 32 32"
            height="32px"
            version="1.1"
            viewBox="0 0 32 32"
            width="32px"
            space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            className="arrow-icon"
          >
            <path
              clipRule="evenodd"
              d="M32,16.009c0-0.267-0.11-0.522-0.293-0.714  l-9.899-9.999c-0.391-0.395-1.024-0.394-1.414,0c-0.391,0.394-0.391,1.034,0,1.428l8.193,8.275H1c-0.552,0-1,0.452-1,1.01  s0.448,1.01,1,1.01h27.586l-8.192,8.275c-0.391,0.394-0.39,1.034,0,1.428c0.391,0.394,1.024,0.394,1.414,0l9.899-9.999  C31.894,16.534,31.997,16.274,32,16.009z"
              fill="#fff"
              fillRule="evenodd"
              id="Arrow_Forward"
            ></path>
          </svg>
        </a>
      </li>
    )
  })

  return (
    <Layout>
      <SEO title="Blog" />
      <div className="blog-layout">
        <section className="intro intro--no-waves">
        
          <div className="container">
            <Navbar />
            <div className="container-blog container text-left">
              <h1 className="labs-title">
                Labs42 Blog
                <span className="labs-blue">.</span>
              </h1>
            </div>
          </div>
        </section>
        <section className="wave-wrapper">
          <section className="blog-post-wrapper dark-bg blog-list-wrapper">
            <div className="sticky-social"></div>

            <div className="container-blog container-blog-content container">
              <div className="blog-post dark-bg">
                <div className="blog-list">
                  <ul className="blog-post-list">{renderItems}</ul>
                </div>
                <div id="bottom-social">
                  <div id="newsletter">
                    <h2>
                      <span role="img" aria-label="">
                        📬
                      </span>
                      Labs42 Newsletter
                    </h2>

                    <p>Stay up to date with all the latest Articles & News!</p>
                    <NewsletterForm />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="blog-post-wrapper dark-bg"></section>
        </section>
        <div className="dark-bg">
          <section className="dark-bg wwd-wrapper pb-5 margin-top-rwd">
            <Services />
          </section>
        </div>

        <Footer />
      </div>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query {
    allBlogItemsJson {
      nodes {
        id
        dateTime
        title
        summary
        image
        link
        author
      }
    }
  }
`
